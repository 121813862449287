body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.sticky-column {
  position: sticky;
  left: 0;
  z-index: 1; /* Öne çıkması için */
  background: white; /* Arka planı beyaz tut */
  border-right: 1px solid #ccc; /* Görünürlük için kenarlık */
}
