@import "react-lazy-load-image-component/src/effects/blur.css";

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
button,
li,
td,
th {
  font-family: Poppins-Regular;
}

body {
  position: relative;
}

.App {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .mantine-AppShell-body {
    padding-bottom: 4rem !important;
  }
}

.mantine-AppShell-root {
  overflow-x: hidden;
}

.mantine-AppShell-body {
  position: relative;
  background: url("/public/assets/images/logoicon.png") #eef0f8;
  background-repeat: no-repeat;
  background-position: 12% 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.mantine-Header-root {
  background: transparent;
  border: none;
  padding-bottom: 0;
  position: absolute;
}

.mantine-Footer-root {
  position: relative;
  border: none;
  background: #eef0f8;
  bottom: 0;
  z-index: 0;
}

@media (max-width: 768px) {
  .mantine-Footer-root {
    width: 100vw !important;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*Profile*/
.profile-border-box {
  border: 1px solid #d8dae2;
  border-radius: 2rem;
  overflow: hidden;
}

.profile-paper {
  border-radius: 2rem;
}

.profile-info-field-left {
  background: #eef0f8;
  border-right: 1px solid #d8dae2;
}

.profile-info-field-right {
  padding-left: 14px;
}

.profile-info-field-box {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #d8dae2;
}
.profile-info-aggrement-box {
  border: 1px solid #d8dae2;
  text-align: "center";
}

.profile-border-bottom-reset {
  border-bottom: none;
  margin-bottom: calc(-1rem / 2);
}

.profile-title {
  color: #136583;
  font-size: 0.95rem;
  font-family: Poppins-Medium !important;
  padding: 6px 20px;
}

.profile-detail {
  color: #000000;
  font-size: 0.9rem;
  font-family: Poppins-light !important;
  padding: 6px 2px;
}

/*Datatable*/

.datatable {
  border: 1px solid #d8dae2;
  border-radius: 2rem;
  overflow: hidden;
}

.datatable th {
  background: #eef0f8;
  color: #136583 !important;
  font-weight: 600 !important;
  height: 50px;
}

.datatable td {
  color: #000000;
  font-weight: 300 !important;
  font-size: 0.8rem !important;
  height: 50px;
}

.datatable {
  color: transparent;
}

.datatable button {
  border: 1px solid #d8dae2;
}

.datatable button[data-active="true"] {
  background: #0097c4;
}

.blue-colored-text {
  color: #136583;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.custom-select input {
  border-radius: 1.25rem !important;
  height: 50px !important;
}

.filter-button {
  border-radius: 1.75rem !important;
  height: 40px;
  background-color: #0097c4 !important;
  width: 100% !important;
}

/*Modal*/
.state-modal-button {
  border-radius: 1.75rem !important;
  height: 40px;
  width: 100% !important;
  font-weight: 500 !important;
}

.success-modal-button {
  background-color: #0097c4 !important;
}

.custom-modal .modalBody {
  border-radius: 2rem !important;
}

.login-background-box {
  overflow: hidden !important;
}

.login-background-box .lazy-load-image-background {
  width: 100% !important;
}

.login-background-box .login-right-image {
  width: 100% !important;
  height: 100vh !important;
  object-fit: cover;
}

.login-input input {
  border-radius: 1.2rem !important;
  height: 50px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  border: 1px solid #d8dae2 !important;
}

.login-background-box label {
  color: #000000 !important;
}

.login-background-box .login-button {
  border-radius: 1.75rem !important;
  height: 40px !important;
  width: 100% !important;
  font-weight: 500 !important;
  background-color: #0097c4 !important;
}

.login-stepper {
  border-radius: 50% !important;
  padding: 6px;
}

.login-stepper-selected {
  background-color: #0097c4 !important;
  color: #ffffff !important;
}

.login-stepper-unselected {
  background-color: #f1f3f5 !important;
  border: 2px solid #0097c4 !important;
  color: #f1f3f5 !important;
}
nav .mantine-Text-root {
  font-family: Poppins-Regular !important;
}

nav .mantine-Menu-item:hover .mantine-Text-root,
nav .mantine-Menu-item:focus .mantine-Text-root,
nav .mantine-Menu-item:active .mantine-Text-root {
  color: #136583 !important;
  font-family: Poppins-Medium !important;
}

nav .mantine-Menu-item:hover,
nav .mantine-Menu-item:focus,
nav .mantine-Menu-item:active {
  background-color: #eef0f8 !important;
}

nav .mantine-Menu-item {
  border-radius: 2rem;
  width: 100%;
  padding: 6px !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

nav .mantine-Menu-dropdown {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 767px) {
  nav .mantine-Menu-item {
    margin-bottom: 1rem !important;
    margin: 0 auto;
  }

  nav .mantine-Menu-dropdown {
    left: calc(50% - 50%) !important;
  }
}

.mantine-AppShell-main {
  padding-bottom: 5rem;
}
.custom-tabs button {
  border-radius: 32rem !important;
}
.custom-tabs [data-active] {
  background-color: #ffffff !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 32rem !important;
  color: #000000 !important;
}

/*.custom-tabs button:hover,
.custom-tabs button:focus,
.custom-tabs button:active {
  background-color: #ffffff !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 32rem !important;
  color: #000000 !important;
}*/

.user-profile-form input {
  height: 2.8rem;
  border-radius: 1.05rem;
}

.user-profile-form label {
  font-size: 0.85rem;
  padding-bottom: 0.4rem;
}

.user-profile-form button[type="submit"] {
  border-radius: 1.75rem !important;
  height: 40px;
  background-color: #0097c4 !important;
}

.header-dropdown-list {
  list-style: none;
}

.header-dropdown-list a {
  text-decoration: none;
  color: #000;
  font-family: Poppins-Regular !important;
  font-size: 0.9rem;
}

.header-dropdown-list li {
  width: 100%;
  padding: 2px;
  border-radius: 2rem;
}

.header-dropdown-list .mantine-List-itemWrapper {
  padding-left: 0.1rem;
}

.header-dropdown-list li:hover,
.header-dropdown-list li:focus,
.header-dropdown-list li:active {
  background-color: #eef0f8 !important;
}
.header-dropdown-list li:hover a,
.header-dropdown-list li:focus a,
.header-dropdown-list li:active a {
  color: #136583 !important;
  font-family: Poppins-Medium !important;
}

.header-dropdown-menu-paper {
  position: absolute;
  top: 4rem;
  right: 0;
  padding: 10px 10px;
  border-radius: 0.4rem;
  width: 60%;
  border: 1px solid rgb(0, 151, 196);
}
@media screen and (max-width: 767px) {
  .header-dropdown-menu-paper {
    width: 100%;
  }
}

.otp-pin-input input {
  width: 4rem !important;
  height: 4rem !important;
  font-size: 1.2rem !important;
}

@media (max-width: 48em) {
  .otp-pin-input input {
    width: 2.5rem !important;
    height: 2.5rem !important;
    font-size: 1rem !important;
  }
}

.multi-select-add-user .mantine-MultiSelect-input {
  border-radius: 32px !important;
  min-height: 48px !important;
}

.multi-select-add-user .mantine-MultiSelect-values {
  min-height: 46px !important;
}

.multi-select-add-users-card .mantine-MultiSelect-input {
  border-radius: 18px !important;
  height: 48px !important;
}

.multi-select-add-users-card .mantine-MultiSelect-values {
  min-height: 46px !important;
}

.user-profile-form input:disabled {
  color: #333333 !important;
  font-family: Poppins-Medium !important;
  font-size: 14px;
}
.mantine-Table-root td {
  font-family: Poppins-Medium !important;
}

.mantine-Breadcrumbs-root .mantine-Text-root {
  font-family: Poppins-light !important;
}

.mantine-InputWrapper-root label {
  font-family: Poppins-medium !important;
}

.mantine-Button-root .mantine-Text-root {
  font-family: Poppins-light !important;
}
.col-md-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-md-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.react-form-builder {
  width: 100%;
}
.react-form-builder .react-form-builder-toolbar {
  margin-top: 0px !important;
}
.react-form-builder .react-form-builder-toolbar {
  position: fixed;
  right: 5%;
  width: 242px !important;
  height: 500px;
  overflow: auto;
}
.react-form-builder
  .react-form-builder-preview
  .Sortable
  .rfb-item.SortableItem
  .form-group
  label,
.react-form-builder
  .react-form-builder-preview
  .Sortable
  .rfb-item.SortableItem
  .form-group
  select,
.react-form-builder
  .react-form-builder-preview
  .Sortable
  .rfb-item.SortableItem
  .form-group
  input,
.react-form-builder
  .react-form-builder-preview
  .Sortable
  .rfb-item.SortableItem
  .form-group
  a {
  padding-left: 10px;
}
.react-form-builder-form .rfb-item .row {
  margin-top: 0px !important;
}
.svc-creator__banner {
  display: none !important;
}

.react-form-builder-form .rfb-item .row {
  border: none !important;
  padding: 0px !important;
}
.react-form-builder-form .rfb-item .custom-control-label {
  margin-left: 10px;
}
div:where(.swal2-container) h2:where(.swal2-title) {
  font-size: 1.2em !important;
}
